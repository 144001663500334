import { render, staticRenderFns } from "./SearchPanel.vue?vue&type=template&id=51091972&"
import script from "./SearchPanel.vue?vue&type=script&lang=ts&"
export * from "./SearchPanel.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductDiscountLabels: require('/azp/_work/13/a/NuxtApp/components/product/DiscountLabels.vue').default,BaseButton: require('/azp/_work/13/a/NuxtApp/components/base/Button.vue').default,BaseIcon: require('/azp/_work/13/a/NuxtApp/components/base/Icon.vue').default})
