import { render, staticRenderFns } from "./MenuBtn.vue?vue&type=template&id=4b853c48&"
import script from "./MenuBtn.vue?vue&type=script&lang=ts&"
export * from "./MenuBtn.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/azp/_work/13/a/NuxtApp/components/base/Icon.vue').default})
