import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67ed89be = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _e2e16c7e = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _18fe4f34 = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _90258d86 = () => interopDefault(import('../pages/account/funds.vue' /* webpackChunkName: "pages/account/funds" */))
const _643c388c = () => interopDefault(import('../pages/account/loyalities.vue' /* webpackChunkName: "pages/account/loyalities" */))
const _344f89da = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _69fa40da = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _6a32d901 = () => interopDefault(import('../pages/account/purchases.vue' /* webpackChunkName: "pages/account/purchases" */))
const _3dabc236 = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _9b9ff9b4 = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _cbc048fc = () => interopDefault(import('../pages/account/details/delivery.vue' /* webpackChunkName: "pages/account/details/delivery" */))
const _e3b28ce2 = () => interopDefault(import('../pages/account/details/invoice.vue' /* webpackChunkName: "pages/account/details/invoice" */))
const _3ab41b6a = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _67c13bea = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _a40d007a = () => interopDefault(import('../pages/account/details/store.vue' /* webpackChunkName: "pages/account/details/store" */))
const _1a58106e = () => interopDefault(import('../pages/product/lister/actie.vue' /* webpackChunkName: "pages/product/lister/actie" */))
const _6114f022 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _a2f59ef6 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _9d6b0170 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _b7a27a24 = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _0b731fbc = () => interopDefault(import('../pages/checkout/information.vue' /* webpackChunkName: "pages/checkout/information" */))
const _21b449f3 = () => interopDefault(import('../pages/checkout/overview.vue' /* webpackChunkName: "pages/checkout/overview" */))
const _1bb80efc = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _519f4458 = () => interopDefault(import('../pages/checkout-redirect.vue' /* webpackChunkName: "pages/checkout-redirect" */))
const _1c8c8bbb = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _99378c90 = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _31066fc2 = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _5851a386 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _9555064a = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _dae9cd0a = () => interopDefault(import('../pages/store/lister/index.vue' /* webpackChunkName: "pages/store/lister/index" */))
const _22c13a99 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _9ff4d5f2 = () => interopDefault(import('../pages/wishlist/index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _9ba18f46 = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _68f8aed4 = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _2b7d802c = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _61ef7f7a = () => interopDefault(import('../pages/login/guest.vue' /* webpackChunkName: "pages/login/guest" */))
const _c7b2bc36 = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _272e5fea = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _7df210b4 = () => interopDefault(import('../pages/review/detail/_slug.vue' /* webpackChunkName: "pages/review/detail/_slug" */))
const _26c19c42 = () => interopDefault(import('../pages/review/writing/_slug.vue' /* webpackChunkName: "pages/review/writing/_slug" */))
const _11547f2b = () => interopDefault(import('../pages/product/detail/_slug.vue' /* webpackChunkName: "pages/product/detail/_slug" */))
const _2732d98e = () => interopDefault(import('../pages/store/detail/_slug.vue' /* webpackChunkName: "pages/store/detail/_slug" */))
const _92325cbc = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _e88b98a8 = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _71d49c7c = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _13363e22 = () => interopDefault(import('../pages/brand/lister/_.vue' /* webpackChunkName: "pages/brand/lister/_" */))
const _631547fa = () => interopDefault(import('../pages/product/lister/_.vue' /* webpackChunkName: "pages/product/lister/_" */))
const _42fc4fba = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _38afe5b0 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _67ed89be,
    children: [{
      path: "",
      component: _e2e16c7e,
      name: "account___nl"
    }, {
      path: "details",
      component: _18fe4f34,
      name: "account-details___nl"
    }, {
      path: "funds",
      component: _90258d86,
      name: "account-funds___nl"
    }, {
      path: "loyalities",
      component: _643c388c,
      name: "account-loyalities___nl"
    }, {
      path: "newsletter",
      component: _344f89da,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _69fa40da,
      name: "account-overview___nl"
    }, {
      path: "purchases",
      component: _6a32d901,
      name: "account-purchases___nl"
    }, {
      path: "transactions",
      component: _3dabc236,
      name: "account-transactions___nl"
    }, {
      path: "wishlist",
      component: _9b9ff9b4,
      name: "account-wishlist___nl"
    }, {
      path: "details/delivery",
      component: _cbc048fc,
      name: "account-details-delivery___nl"
    }, {
      path: "details/invoice",
      component: _e3b28ce2,
      name: "account-details-invoice___nl"
    }, {
      path: "details/login",
      component: _3ab41b6a,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _67c13bea,
      name: "account-details-profile___nl"
    }, {
      path: "details/store",
      component: _a40d007a,
      name: "account-details-store___nl"
    }]
  }, {
    path: "/actie",
    component: _1a58106e,
    name: "product-lister-actie___nl"
  }, {
    path: "/cart",
    component: _6114f022,
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _a2f59ef6,
    children: [{
      path: "",
      component: _9d6b0170,
      name: "checkout___nl"
    }, {
      path: "delivery",
      component: _b7a27a24,
      name: "checkout-delivery___nl"
    }, {
      path: "information",
      component: _0b731fbc,
      name: "checkout-information___nl"
    }, {
      path: "overview",
      component: _21b449f3,
      name: "checkout-overview___nl"
    }, {
      path: "payment",
      component: _1bb80efc,
      name: "checkout-payment___nl"
    }]
  }, {
    path: "/checkout-redirect",
    component: _519f4458,
    name: "checkout-redirect___nl"
  }, {
    path: "/checkout-success",
    component: _1c8c8bbb,
    name: "checkout-success___nl"
  }, {
    path: "/icon",
    component: _99378c90,
    name: "icon___nl"
  }, {
    path: "/inspiratie",
    component: _31066fc2,
    name: "blog-category___nl"
  }, {
    path: "/login",
    component: _5851a386,
    name: "login___nl"
  }, {
    path: "/merk",
    component: _9555064a,
    name: "brand___nl"
  }, {
    path: "/vestigingen",
    component: _dae9cd0a,
    name: "store-lister___nl"
  }, {
    path: "/wachtwoord-vergeten",
    component: _22c13a99,
    name: "forgot-password___nl"
  }, {
    path: "/wishlist",
    component: _9ff4d5f2,
    name: "wishlist___nl"
  }, {
    path: "/blocks/demo",
    component: _9ba18f46,
    name: "blocks-demo___nl"
  }, {
    path: "/forgot-password/reset",
    component: _68f8aed4,
    name: "forgot-password-reset___nl"
  }, {
    path: "/klantenservice/faq",
    component: _2b7d802c,
    name: "faq___nl"
  }, {
    path: "/login/guest",
    component: _61ef7f7a,
    name: "login-guest___nl"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _c7b2bc36,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/inspiratie/post/:slug?",
    component: _272e5fea,
    name: "blog-detail-slug___nl"
  }, {
    path: "/review/detail/:slug?",
    component: _7df210b4,
    name: "review-detail-slug___nl"
  }, {
    path: "/review/writing/:slug?",
    component: _26c19c42,
    name: "review-writing-slug___nl"
  }, {
    path: "/p/:slug?",
    component: _11547f2b,
    name: "product-detail-slug___nl"
  }, {
    path: "/vestigingen/:slug?",
    component: _2732d98e,
    name: "store-detail-slug___nl"
  }, {
    path: "/zoek/:slug?",
    component: _92325cbc,
    name: "search-slug___nl"
  }, {
    path: "/inspiratie/*",
    component: _e88b98a8,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _71d49c7c,
    name: "blog-tag-all___nl"
  }, {
    path: "/m/*",
    component: _13363e22,
    name: "brand-lister-all___nl"
  }, {
    path: "/producten/*",
    component: _631547fa,
    name: "product-lister-all___nl"
  }, {
    path: "/",
    component: _42fc4fba,
    name: "index___nl"
  }, {
    path: "/*",
    component: _38afe5b0,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
